import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import Form from "../components/form"
import ScrollAnimation from "react-animate-on-scroll"

import { FormPageStyle } from "../styles/contacto"

const Contacto = props => {
  const { contactPage3 } = props.data.dataJson || {}

  return (
    <>
      <SEO
        title="Contacto"
        image="/images/contactos.jpg"
        imageWidth="1200"
        imageHeight="628"
        pathname="/contacto"
        description="Contacte a Sicarpo para qualquer esclarecimento."
      />
      {props.data && props.data.dataJson ? (
        <FormPageStyle>
          <div
            className="startArea"
            style={{ backgroundImage: `url(${contactPage3.img.publicURL})` }}
          >
            <div className="container">
              <div className="row">
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <h1 className="title">{contactPage3.title}</h1>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInDown"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <p className="text">{contactPage3.text}</p>
                </ScrollAnimation>
              </div>
              <div className="row">
                <Form formType="contacto" />
              </div>
              <div
                className="row"
                style={{ alignSelf: "end", textAlign: "center" }}
              >
                <Link
                  to="/contacto/#map"
                  onClick={props.removeHash}
                  className="text text-map"
                >
                  Desça para ver o mapa
                </Link>
              </div>
              {/* <div className="row contact">
                        <div className="first-contact">
                            <h2 className="mini-title">{contactPage3.contact.title1}</h2>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp1_1}</p>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp1_2}</p>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp1_3}</p>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp1_4}</p>
                        </div>
                        <div className="second-contact">
                            <h2 className="mini-title">{contactPage3.contact.title2}</h2>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp2_1}</p>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp2_2}</p>
                            <p style={{color: `#cbcbca`}}>{contactPage3.contact.dcp2_3}</p>
                        </div>   
                    </div> */}
            </div>
          </div>
          <div id="map" className="anchor"></div>
          <div className="map-container">
            <iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=sicarpo&t=&z=13&ie=UTF8&iwloc=&output=embed&t=k" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
        </FormPageStyle>
      ) : (
        <div className="isLoading">A carregar...</div>
      )}
    </>
  )
}

export default Contacto

export const ContactoQuery = graphql`
  query ContactoQuery {
    dataJson {
      contactPage3 {
        img {
          publicURL
        }
        title
        text
        contact {
          title1
          dcp1_1
          dcp1_2
          dcp1_3
          dcp1_4
          title2
          dcp2_1
          dcp2_2
          dcp2_3
        }
      }
    }
  }
`
