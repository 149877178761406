import styled from "styled-components"
import { colors } from "./variables"

export const FormPageStyle = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #f5f5f5;
    box-shadow: 0 0 0px 1000px #343434 inset;
    -webkit-box-shadow: 0 0 0px 1000px #343434 inset;
    padding-left: 0.5rem;
  }

  .startArea {
    position: relative;
    min-height: 70vh;
    height: 100%;
    padding: 20vh 10vw 10vh 10vw;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 1;
    }
  }

  .container {
    width: 60%;
    display: grid;
    grid-template-rows: auto 1fr 0.3fr;
    grid-row-gap: 40px;
    align-items: center;
  }

  .right {
    align-self: center;
    display: grid;
  }

  .title {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Barlow", sans-serif;
    font-size: 4rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: 1.04px;
  }
  .text {
    font-family: "Barlow", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: #f5f5f5;
    width: fit-content;
  }
  .text-map {
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 0 auto;
  }

  .mini-title {
    font-family: "Barlow", sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: left;
    color: #f5f5f5;
  }

  .input {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .input-textarea {
    width: 100%;
  }

  .startArea .modal-wrapper {
    display: grid;
    width: 100%;
    height: 100%;
  }
  .startArea .modal {
    justify-self: center;
    align-self: center;
    width: fit-content;
    font-family: "Barlow", sans-serif;
    font-size: 1.8rem;
    color: #ffffff;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 50px;
    color: #f5f5f5;

    input {
      background: none;
      border: none;
      border-bottom: 1.2px solid #cbcbca;
      margin-bottom: 25px;
      height: 2.5rem;
      font-family: "Barlow", sans-serif;
      font-size: 1.5rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #cbcbca;

      &:focus {
        outline: 0;
        background: none;
      }

      ::placeholder {
        font-family: "Barlow", sans-serif;
        font-size: 1.5rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.79;
        letter-spacing: normal;
        text-align: left;
        color: #cbcbca;
      }
    }

    .containerUpload {
      position: relative;
    }
    .uglyUpload {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .iconUpload {
      position: absolute;
      right: 0;
      top: 0.85rem;
    }

    .input-textarea,
    .tooltipButtonContainer {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    textarea {
      resize: none;
      height: 5.5rem;
      background: none;
      border: none;
      border-bottom: 1.2px solid #cbcbca;
      padding: 0;
      width: 100%;

      font-family: "Barlow", sans-serif;
      font-size: 1.5rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #cbcbca;

      &:focus {
        outline: 0;
      }

      ::placeholder {
        color: #f5f5f5;
        font-family: "Barlow", sans-serif;
        font-weight: 200;
      }
    }
  }

  .tooltipButtonContainer {
    justify-self: right;
    align-self: end;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 45px;

    background-color: ${colors.orange};
    color: #ffffff;
    border: none;
    box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
    border-radius: 0;
    text-align: center;

    transition: background-color 0.5s ease, box-shadow 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
      box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
    }

    &:hover > .tooltipButton > .tooltipButtonArrow {
      transform: translate(5px);
    }

    .tooltipButton {
      font-size: 1.6rem;
      @media only screen and (min-width: 2100px){
        font-size: 2.2rem;
      }
      width: auto;
      height: auto;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .tooltipButtonArrow {
      fill: #ffffff;
      stroke: #ffffff;
      height: 0.8em;
      width: 25px;
      margin: 0 auto;
      transform: translate(0px);
      transition: transform 0.5s ease;
    }
  }

  .map-container {
    height: 650px;
  }
  .contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
  }
  .first-contact,
  .second-contact {
    display: grid;
    grid-template-rows: 2fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 2%;
  }
  .first-contact {
    justify-self: center;
  }
  .second-contact {
    justify-self: center;
  }

  /**
 * ----------------------------------------
 * Animação de pulsar
 * 
 * ----------------------------------------
 */
  .pulsate-fwd {
    -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
    animation: pulsate-fwd 0.5s ease-in-out infinite both;
  }
  @-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  /**
 * ----------------------------------------
 * Media Queries
 * ----------------------------------------
 */
  @media screen and (min-width: 2560px) {
    .title {
      font-size: 8rem;
      letter-spacing: normal;
    }
    .text {
      font-size: 3.2rem;
    }
  }
  @media screen and (max-width: 1921px) and (max-width: 2560px) {
    .title {
      font-size: calc(60px + 20 * (100vw - 1920px) / 640);
      letter-spacing: normal;
    }
    .text {
      font-size: calc(24px + 8 * (100vw - 1920px) / 640);
    }
  }

  @media screen and (max-width: 1024px) {
    .startArea {
      h1 {
        font-size: 2.8rem;
      }
      p {
        font-size: 1.4rem;
        width: 100%;
      }
      h2 {
        font-size: 1.6rem;
      }
      .container {
        .right {
          p {
            margin: 2px 0 2px 0;
          }
        }
      }
    }
    .tooltipButtonContainer {
      width: 150px;
      height: 40px;
    }
    .map-container {
      height: 425px;
    }
  }
  @media screen and (max-width: 768px) {
    .startArea {
      padding: 20vh 30px 20vh 30px;
    }
    .startArea .container {
      width: 100%;
      grid-row-gap: 15px;
    }
    .startArea .container h1 {
      font-size: 2.4rem;
      line-height: 30px;
      margin-bottom: 25px;
      text-align: left;
    }

    .startArea .container p {
      font-size: 1.3rem;
    }

    .startArea .container .right {
      width: 100%;
    }

    .form {
      width: 100%;
      grid-column-gap: 25px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr auto 1fr;
    }

    .form .input {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .form input {
      width: 100%;
      font-size: 1.2rem;
      margin-bottom: 20px;
      height: 30px;

      &::placeholder {
        font-size: 1.2rem;
      }
    }
    .form .area {
      font-size: 1.2rem;
      margin-bottom: 20px;
      height: 30px;
    }
    .form textarea {
      font-size: 1.2rem;
    }

    .form .iconUpload {
      top: 5px;
      transform: scale(0.85);
    }

    .tooltipButtonContainer {
      width: 145px;
      height: 35px;
      bottom: -17.5px;
      right: 25px;
      .tooltipButton {
        font-size: 1.2rem;
      }
    }
    .contact {
      grid-template-columns: unset;
      grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
      grid-row-gap: 15px;
      padding-top: 30%;
    }
    .contact .first-contact,
    .contact .second-contact {
      grid-template-rows:
        minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
        minmax(0, 1fr);
      grid-row-gap: unset;
      align-items: center;
      justify-content: center;
    }

    .contact p {
      font-size: 1.2rem;
    }
    .map-container {
      height: 350px;
    }
  }
`
